






















import Vue from "vue";
import { mapActions } from "vuex";
import Popup from "../Popup.vue";
import Input from "../Input.vue";
import Button from "../Button.vue";
import Alert from "../Alert.vue";
export default {
  name: "LoginModal",
  components: {
    Popup,
    Button,
    Input,
    Alert,
  },
  data() {
    return {
      email: "",
      emailError: null,
      password: "",
      passwordError: null,
      error: null,
      loading: false,
    };
  },
  props: {
    show: Boolean,
  },
  methods: {
    ...mapActions("notification", ["addNotification"]),
    ...mapActions("authUser", ["setUser"]),
    login(): void {
      this.error = null;
      this.emailError = null;
      this.passwordError = null;

      if (this.email.length === 0) {
        this.emailError = this.$t("errors.emailError");
      } else if (this.password.length === 0) {
        this.passwordError = this.$t("errors.passwordError");
      } else {
        this.loading = true;
        Vue.$sdk.Auth.login(
          { email: this.email, password: this.password },
          {
            success: ({ email, token, _id, firstname, lastname }) => {
              this.setUser({ email, token, _id, firstname, lastname });
              this.loading = false;
              this.addNotification({
                icon: require("@/assets/img/icons/check-circle.svg"),
                title: this.$t("notifications.connectSuccessTitle"),
                text: this.$t("notifications.connectSuccessMessage"),
              });
              this.$emit("close");
            },
            error: (e) => {
              if (e.response) {
                switch (e.response.status) {
                  case 404: {
                    this.error = this.$t("errors.userNotFound404");
                    break;
                  }
                  case 500: {
                    this.error = this.$t("errors.serverError500");
                    break;
                  }
                  default: {
                    this.error = this.$t("errors.unknownError");
                  }
                }
              } else {
                this.error = this.$t("errors.unknownError");
              }
              this.loading = false;
            },
          }
        );
      }
    },
  },
  watch: {
    email() {
      this.emailError = null;
      this.error = null;
    },
    password() {
      this.passwordError = null;
      this.error = null;
    },
  },
};
