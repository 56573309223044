












import Vue from "vue";
import { mapState, mapActions } from "vuex";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import CookieModal from "./components/Modals/CookieModal.vue";
import AppsModal from "./components/Modals/AppsModal.vue";
import NeedToChangeUserModal from "./components/Modals/NeedToChangeUserModal.vue";
import FoodCardVue from "./components/FoodCard.vue";

export default Vue.extend({
  components: {
    Header,
    Footer,
    CookieModal,
    AppsModal,
    NeedToChangeUserModal,
  },

  data() {
    return {
      showCookieModal: false,
      showAppsModal: false,
      showNeedToChangeUserModal: false,
    };
  },
  mounted: function () {
    this.getCustomerFromParams();
  },

  computed: {
    ...mapState("authUser", ["authRequested"]),
    ...mapState("authUser", {
      userCustomer: (state) => {
        console.log(state);
        return state.customer;
      },
    }),
    ...mapState("search", ["customer"]),
    ...mapState("meta", ["theme", "isNativeVersion", "isMobile"]),
  },
  methods: {
    ...mapActions("authUser", ["clear"]),
    ...mapActions("search", ["setCustomer"]),
    ...mapActions("meta", ["setTheme"]),
    switchUser(): void {
      this.showNeedToChangeUserModal = false;
      this.clear();
      Vue.$sdk.Auth.remove_auth_user();
      setTimeout(() => {
        location.reload();
      }, 1000);
    },
    keepUser(): void {
      this.showNeedToChangeUserModal = false;

      Vue.$sdk.Customer.get_details(this.userCustomer, {
        success: (customer) => {
          this.saveCustomer(customer);
        },
        error: (error) => {
          console.log(error);
          alert("Error: customer id in url could not be found");
        },
      });
    },
    getCustomerFromParams(): void {
      const customerIdInParams = this.$route.query.customer;
      if (customerIdInParams) {
        Vue.$sdk.Customer.get_details(customerIdInParams, {
          success: (customer) => {
            this.saveCustomer(customer);
          },
          error: (error) => {
            console.log(error);
            alert("Error: customer id in url could not be found");
          },
        });
      } else {
        this.getCustomerFromDomain();
      }
    },
    getCustomerFromDomain() {
      Vue.$sdk.Customer.get_details_from_domain(location.hostname, {
        success: (customer) => {
          this.saveCustomer(customer);
        },
        error: (error) => {
          console.log(error);
          alert("Error: customer for current domain could not be found");
        },
      });
    },
    saveCustomer(customer) {
      this.setCustomer(customer);
      this.detectTheme();
      this.detectLang();
      console.log(this.userCustomer, this.customer._id);
      if (this.userCustomer && this.userCustomer !== this.customer._id) {
        this.showNeedToChangeUserModal = true;
      }
    },
    //[To-Do]: To be re-implemented
    detectTheme() {
      var host = location.hostname;
      /*if(!host){ 
          $rootScope.is_native_version = true;
          return 'u';
      } else */
      if (
        host.indexOf("d2jppkwagmam1i.cloudfront.net") > -1 ||
        host.indexOf("quelvinetesvous.fr") > -1 ||
        host.indexOf("qvev.fr") > -1
      ) {
        this.setTheme("u");
      } else {
        this.setTheme(this.$route.query.theme);
      }
      if (this.isMobile && !this.isNativeVersion && this.theme === "u") {
        this.showAppsModal = true;
      }
    },
    detectLang() {
      if (this.$route.query.lang) {
        this.$i18n.locale = this.$route.query.lang;
      } else {
        this.$i18n.locale = this.customer.lang;
      }
    },
  },
});
