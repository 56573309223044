




















import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      internalValue: null,
    };
  },
  props: {
    error: String,
    label: String,
    placeholder: String,
    value: Object,
    items: Array,
    loading: Boolean,
  },
  mounted() {
    if (this.value) {
      this.internalValue = this.value;
    }
    this.$refs.scrollbox.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll({ target }) {
      if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
        this.$emit("scrollEnd");
      }
    },
    onClick(item) {
      this.internalValue = item;
      this.$emit("input", this.internalValue);
    },
  },
});
