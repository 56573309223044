import Vue from "vue";
import App from "./App.vue";
import SdkPlugin from "./plugins/SdkPlugin";
import VModal from "vue-js-modal";
import VueGtag from "vue-gtag";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueWordCloud from "vuewordcloud";
import VueI18n from "vue-i18n";
import localeFrench from "./locales/fr";
import localeEnglish from "./locales/en";

Vue.config.productionTip = false;
Vue.use(VModal);
Vue.use(SdkPlugin);
Vue.use(VueI18n);

Vue.use(
  VueGtag,
  {
    config: {
      id: "UA-141004337-1",
      cookie_domain: "quelvinetesvous.fr",
    },
    bootstrap: false,
    params: {
      //send_page_view: false
    },
  },
  router
);

Vue.$sdk.init("prod", {
  on_auth_user_changed: function (auth_user) {
    store.dispatch("authUser/setAuthRequested", true);
    if (auth_user) {
      store.dispatch("authUser/setUser", {
        _id: auth_user._id,
        token: auth_user.token,
        email: auth_user.email,
        firstname: auth_user.firstname,
        lastname: auth_user.lastname,
        customer: auth_user.customer_reference,
      });
    }
  },
});

Vue.component(VueWordCloud.name, VueWordCloud);

const i18n = new VueI18n({
  locale: "fr",
  messages: {
    fr: localeFrench,
    en: localeEnglish,
  },
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
