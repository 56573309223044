var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"progress-step"},[_c('div',{staticClass:"bar-container"},[_c('div',{staticClass:"bar-placeholder"}),_c('div',{staticClass:"bar",style:(_vm.progressStyle)}),_c('div',{staticClass:"step",class:{
        'is-active': _vm.progessStep >= 0,
        'is-current': _vm.progessStep === 0,
      }},[_c('img',{attrs:{"src":require("../assets/img/icons/fruit.svg")}}),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("conceptAnimation.line1")))]),_c('span',[_vm._v(_vm._s(_vm.$t("conceptAnimation.line2")))])])]),_c('div',{staticClass:"step",class:{
        'is-active': _vm.progessStep >= 1,
        'is-current': _vm.progessStep === 1,
      }},[_c('img',{attrs:{"src":require("../assets/img/icons/tech.svg")}}),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("conceptAnimation.line3")))]),_c('span',[_vm._v(_vm._s(_vm.$t("conceptAnimation.line4")))])])]),_c('div',{staticClass:"step",class:{
        'is-active': _vm.progessStep >= 2,
        'is-current': _vm.progessStep === 2,
      }},[_c('img',{attrs:{"src":require("../assets/img/icons/wine.svg")}}),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("conceptAnimation.line5")))]),_c('span',[_vm._v(_vm._s(_vm.$t("conceptAnimation.line6")))])])]),_c('div',{staticClass:"step",class:{
        'is-active': _vm.progessStep >= 3,
        'is-current': _vm.progessStep === 3,
      }},[_c('img',{attrs:{"src":require("../assets/img/icons/affection.svg")}}),_c('p',[_c('span',[_vm._v(_vm._s(_vm.$t("conceptAnimation.line7")))]),_c('span',[_vm._v(_vm._s(_vm.$t("conceptAnimation.line8")))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }