

















import Vue from "vue";
import { mapActions } from "vuex";
import Card from "../components/Card.vue";
import Activity from "../components/Activity.vue";
import Separator from "../components/Separator.vue";
import Button from "../components/Button.vue";
import ToggleSwitch from "../components/ToggleSwitch.vue";

export default Vue.extend({
  name: "Over18",
  components: {
    Card,
    Activity,
    Separator,
    Button,
    ToggleSwitch,
  },
  data: function () {
    return {
      over18: false,
    };
  },
  methods: {
    ...mapActions("notification", ["addNotification"]),
    next() {
      this.addNotification({
        icon: require("@/assets/img/icons/age-18-validated.svg"),
        title: this.$t("over18.successTitle"),
        text: this.$t("successModal.age18"),
      });
      this.$router.push("/home");
    },
  },
});
