























































































import Vue from "vue";
import { mapState } from "vuex";
import Activity from "@/components/Activity.vue";
import Button from "@/components/Button.vue";
import Card from "@/components/Card.vue";
import Row from "@/components/Row.vue";
import Separator from "@/components/Separator.vue";
import Txt from "@/components/Txt.vue";
import PhotoModal from "@/components/Modals/PhotoModal.vue";
import JsBarcode from "jsbarcode";
export default Vue.extend({
  name: "Wine",
  components: {
    Activity,
    Button,
    Card,
    Row,
    Separator,
    Txt,
    PhotoModal,
  },
  data: function (): { wine: any; showPhotoModal: boolean } {
    return {
      wine: null,
      showPhotoModal: false,
    };
  },
  computed: {
    ...mapState("search", ["store"]),
    wineName(): string {
      //return `${this.wineColor} - ${this.data.wine_reference.winery.toLowerCase()}`;
      if (this.wine.wine_reference.cuvee) {
        return this.wine.wine_reference.cuvee.toLowerCase();
      } else if (this.wine.wine_reference.winery) {
        return this.wine.wine_reference.winery.toLowerCase();
      } else if (this.wine.wine_reference.name) {
        return this.wine.wine_reference.name.toLowerCase();
      } else {
        return "Vin inconnu";
      }
    },
    wineSubName(): string {
      return this.wine.wine_reference.denomination.toLowerCase();
    },
    wineColor(): string {
      switch (this.wine.wine_reference.color) {
        case "red": {
          return "Rouge";
        }
        case "white": {
          return "Blanc";
        }
        case "rose": {
          return "Rosé";
        }
        default: {
          return this.wine.color;
        }
      }
    },
    wineMainType(): string {
      switch (this.wine.wine_reference.main_type) {
        case "still": {
          return "Tranquille";
        }
        case "sparkling": {
          return "Effervescent";
        }
        case "sweet": {
          return "Moelleux";
        }
        default: {
          return this.wine.wine_reference.main_type;
        }
      }
    },
    words(): string[] {
      if (this.wine.wine_reference.words) {
        return this.wine.wine_reference.words.map((arr) => {
          arr[0] = this.translateWord(arr[0]);
          return arr;
        });
      } else if (this.wine.wine_reference.description) {
        return this.wine.wine_reference.description.split(" ").map((w) => {
          return [this.translateWord(w.replace(",", "")), Math.random() * 10];
        });
      } else {
        return [];
      }
    },
  },
  mounted(): void {
    Vue.$sdk.Store.get_wine_details(this.$route.query.storeId, this.$route.query.wineId, {
      success: (data) => {
        this.wine = data;
        if (this.wine.wine_reference.gencod) {
          JsBarcode("#barcode", this.wine.wine_reference.gencod);
        }
      },
      error: () => {
        return;
      },
    });
  },
  methods: {
    open(url): void {
      window.open(url);
    },
    translateWord(w) {
      const synonyms = {
        cuir: "leather",
        gibier: "wildfowl",
        moka: "moka",
        vanille: "vanilla",
        "noix de coco": "coconut",
        "bonbon anglais": "british candy",
        banane: "banana",
        beurre: "butter",
        "caramel au beurre": "buttered caramel",
        yaourt: "yoghurt",
        "pomme blette": "overripe apple",
        noix: "walnut",
        curry: "curry",
        ail: "garlic",
        choux: "cabbage",
        rose: "rose",
        citron: "lemon",
        litchi: "leetchi",
        "herbe coupée": "cutted grass",
        "herbe fraîche": "fresh grass",
        animal: "animal",
        boisé: "wooden",
        fermentaire: "fermentation",
        lacté: "milky",
        fruité: "fruity",
        Fruité: "fruity",
        floral: "floral",
        acide: "sour",
        frais: "fresh",
        amer: "bitter",
        sucré: "sweet",
        chaud: "hot",
        astringent: "astringent",
        rapeux: "rough",
        rugueux: "gravelly",
        piquant: "biting",
        Epais: "thick",
      };
      if (this.$i18n.locale === "fr") {
        return w;
      } else {
        return synonyms[w] || w;
      }
    },
  },
});
