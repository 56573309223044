






































import Vue from "vue";
import { mapActions, mapState } from "vuex";
import Activity from "@/components/Activity.vue";
import Button from "@/components/Button.vue";
import PageTitle from "@/components/PageTitle.vue";
import Card from "@/components/Card.vue";
import Input from "@/components/Input.vue";
import SelectBox from "@/components/SelectBox.vue";

export default Vue.extend({
  name: "Home",
  components: {
    Activity,
    Button,
    PageTitle,
    Card,
    Input,
    SelectBox,
  },
  data: function () {
    return {
      loading: false,
      query: "",
      searchTimer: null,
      page: 0,
      total: 0,
      countPerPage: 20,
      stores: [],
      selectedStore: null,
    };
  },
  mounted(): void {
    if (this.store) {
      this.selectedStore = { value: this.store._id };
    }
    this.search();
  },
  methods: {
    ...mapActions("search", ["setStore", "setShouldMatch"]),
    search(): void {
      if (!this.loading) {
        this.loading = true;
        Vue.$sdk.Store.list_pages(this.page, this.countPerPage, this.query, this.customer._id, {
          success: (data) => {
            this.stores = this.stores.concat(data.results);
            this.total = data.total;
            this.loading = false;
          },
          error: (error) => {
            console.error(error);
            this.loading = false;
          },
        });
      }
    },
    refreshSearch(): void {
      this.stores = [];
      this.page = 0;

      /*$rootScope.store = null;
        $rootScope.store_id = null;
        $rootScope.wines_count = null;*/

      /*this.loading = true;*/
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(() => {
        this.searchTimer = null;
        this.search();
      }, 600);
    },
    selectStore(): void {
      /*$rootScope.store = store;
        $rootScope.store_id = store._id;
        if($rootScope.store.theme_background){
            document.querySelector('body').style.backgroundImage = "url("+$rootScope.store.theme_background+")";
        }
        if(store.wines){
            $rootScope.wines_count =store.wines;
        } else {
            $rootScope.wines_count = 0;
        }*/
      if (this.store !== this.currentStore) {
        this.setShouldMatch(true);
      }
      this.setStore(this.currentStore);
      Vue.$sdk.User.update_session(
        this._id,
        { store_reference: this.currentStore },
        {
          success: () => {
            this.$router.push("/main");
          },
          error: (e) => {
            alert(e);
          },
        }
      );
    },
    onScrollEnd(): void {
      if (this.stores.length < this.total) {
        this.page++;
        this.search();
      }
    },
  },
  computed: {
    ...mapState("authUser", ["_id"]),
    ...mapState("search", ["store", "customer"]),
    currentStore(): any | null {
      return this.stores.find((s) => s._id === this.selectedStore.value);
    },
    storesItems(): any[] {
      return this.stores.map((store) => {
        return {
          value: store._id,
          name: store.brand + " - " + store.name,
          text: store.address + " (" + store.post_code + ")",
        };
      });
    },
  },
  watch: {
    query() {
      this.refreshSearch();
    },
  },
});
