

































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import Activity from "@/components/Activity.vue";
import PageTitle from "@/components/PageTitle.vue";
import Card from "@/components/Card.vue";

export default Vue.extend({
  name: "Quiz",
  components: {
    Activity,
    PageTitle,
    Card,
  },
});
