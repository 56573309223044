

































































































































import Vue from "vue";
import { mapState, mapActions } from "vuex";
import Activity from "@/components/Activity.vue";
import Button from "@/components/Button.vue";
import FoodCardMini from "@/components/FoodCardMini.vue";
import FoodCard from "@/components/FoodCard.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import PageTitle from "@/components/PageTitle.vue";
import ErrorModal from "@/components/Modals/ErrorModal.vue";
import FoodQuizStartedModal from "@/components/Modals/FoodQuizStartedModal.vue";
import FoodQuizPositiveModal from "@/components/Modals/FoodQuizPositiveModal.vue";
import FoodQuizFinishedModal from "@/components/Modals/FoodQuizFinishedModal.vue";
import NotEnoughFoodModal from "@/components/Modals/NotEnoughFoodModal.vue";
const emojiLove = require("@/assets/img/icons/in-love.svg");
const emojiDead = require("@/assets/img/icons/dead.svg");
export default Vue.extend({
  name: "Quiz",
  components: {
    Activity,
    Button,
    FoodCard,
    FoodCardMini,
    ProgressBar,
    PageTitle,
    ErrorModal,
    FoodQuizStartedModal,
    FoodQuizPositiveModal,
    NotEnoughFoodModal,
    FoodQuizFinishedModal,
  },
  data: function () {
    return {
      showErrorModal: false,
      showNotEnoughFoodModal: false,
      showFoodQuizFinishedModal: false,
      showFoodQuizPositiveModal: false,
      showFoodQuizStartedModal: false,
      errorText: null,
      quizType: "negative",
      currentPageIndex: 0,
      foodList: {
        positive: [],
        negative: [],
      },
      lastAction: "next",
    };
  },
  mounted(): void {
    if (this.tasteCollection.food_length === 0 && !this.tasteCollection._id) {
      this.showFoodQuizStartedModal = true;
    }
    this.init();
  },
  computed: {
    ...mapState("authUser", ["_id", "token"]),
    ...mapState("search", ["tasteCollection", "customer"]),
    displayType() {
      if (this.quizType === "negative") {
        return this.customer.negative_quiz_display || "card";
      } else {
        return this.customer.positive_quiz_display || "card";
      }
    },
    emoji() {
      if (this.quizType === "negative") {
        return emojiDead;
      } else {
        return emojiLove;
      }
    },
    page() {
      return this.pages[this.quizType][this.currentPageIndex];
    },
    currentPages() {
      return [this.pages[this.quizType][this.currentPageIndex]];
    },
    pages() {
      return {
        positive: this.foodList.positive.reduce((pages, food) => {
          const lastPage = pages[pages.length - 1];
          if (lastPage && lastPage.length < (this.displayType === "card" ? 4 : 11)) {
            lastPage.push(food);
          } else {
            pages.push([food]);
          }
          return pages;
        }, []),
        negative: this.foodList.negative.reduce((pages, food) => {
          const lastPage = pages[pages.length - 1];
          if (lastPage && lastPage.length < (this.displayType === "card" ? 4 : 11)) {
            lastPage.push(food);
          } else {
            pages.push([food]);
          }
          return pages;
        }, []),
      };
    },
  },
  methods: {
    ...mapActions("notification", ["addNotification"]),
    ...mapActions("search", ["setShouldMatch"]),
    init(): void {
      Vue.$sdk.Food.get_remaining_questions(this._id, {
        success: (data) => {
          this.foodList = data;
          if (data.positive.length === 0 && data.negative.length === 0) {
            alert("Error: Your customer doesnt have any food or quizz");
          }
        },
        error: (e) => {
          this.showErrorModal = true;
          this.errorText = "Impossible de charger la liste des aliments, veuillez réessayer";
        },
      });
    },
    foodClick(food) {
      Vue.set(food, "selected", !food.selected);
    },
    next() {
      this.lastAction = "next";
      if (this.quizType === "negative") {
        if (this.currentPageIndex === this.pages.negative.length - 1) {
          if (this.tasteCollection.food_length === 0 && !this.tasteCollection._id) {
            this.showFoodQuizPositiveModal = true;
          }
          this.currentPageIndex = 0;
          this.quizType = "positive";
        } else {
          this.currentPageIndex++;
        }
      } else if (this.quizType === "positive") {
        if (this.currentPageIndex === this.pages.positive.length - 1) {
          this.beforeSave();
        } else {
          this.currentPageIndex++;
        }
      }
    },
    previous() {
      this.lastAction = "previous";
      if (this.quizType === "negative") {
        if (this.currentPageIndex === 0) {
          this.$router.push("/main");
        } else {
          this.currentPageIndex--;
        }
      } else if (this.quizType === "positive") {
        if (this.currentPageIndex === 0) {
          this.currentPageIndex = this.pages.negative.length - 1;
          this.quizType = "negative";
        } else {
          this.currentPageIndex--;
        }
      }
    },
    beforeSave() {
      const positiveSelectedCount = this.foodList.positive.reduce((sum, f) => {
        return sum + (f.selected ? 1 : 0);
      }, 0);
      //Check wether user selected enough food
      if (
        this.customer.positive_quiz_display === "card" &&
        positiveSelectedCount < 3 &&
        this.tasteCollection.food_length === 0
      ) {
        this.showNotEnoughFoodModal = true;
        return;
      }
      if (this.tasteCollection.food_length === 0 && !this.tasteCollection._id) {
        this.showFoodQuizFinishedModal = true;
      } else {
        this.save();
      }
    },
    save() {
      const payload = {
        food_tastes: this.foodList.positive
          .map((f) => {
            const taste = { food_reference: f._id };
            taste.likes = f.selected ? true : null;
            return taste;
          })
          .concat(
            this.foodList.negative.map((f) => {
              const taste = { food_reference: f._id };
              taste.likes = f.selected ? false : null;
              return taste;
            })
          ),
      };
      Vue.$sdk.User.update_tastes_by_id(this._id, this.tasteCollection ? this.tasteCollection._id : "", payload, {
        success: () => {
          this.setShouldMatch(true);
          if ((this.tasteCollection && this.tasteCollection.food_length === 0) || !this.tasteCollection) {
            Vue.$sdk.User.validate_achievement(this._id, "profile_30_percents", {
              success: () => {
                this.addNotification({
                  icon: require("@/assets/img/icons/achievements/food-1.svg"),
                  title: this.$t("over18.successTitle"),
                  text: this.$t("successModal.complete30"),
                });
              },
              error: (e) => {
                return;
              },
            });
          } else if (this.tasteCollection.food_length > 60) {
            Vue.$sdk.User.validate_achievement(this._id, "profile_100_percents", {
              success: () => {
                this.addNotification({
                  icon: require("@/assets/img/icons/achievements/food-3.svg"),
                  title: this.$t("over18.successTitle"),
                  text: this.$t("successModal.complete100"),
                });
              },
              error: (e) => {
                return;
              },
            });
          } else if (this.tasteCollection.food_length > 30) {
            Vue.$sdk.User.validate_achievement(this._id, "profile_60_percents", {
              success: () => {
                this.addNotification({
                  icon: require("@/assets/img/icons/achievements/food-2.svg"),
                  title: this.$t("over18.successTitle"),
                  text: this.$t("successModal.complete60"),
                });
              },
              error: (e) => {
                return;
              },
            });
          }
          this.$router.push("/main");
          Vue.$sdk.Log.save(
            {
              user_reference: this._id,
              value: "quizz_finished",
            },
            {
              success: () => {
                return;
              },
              error: () => {
                return;
              },
            }
          );
        },
        error: (e) => {
          // [TO-DO] : Display popup
          console.error(e);
        },
      });
    },
  },
});

/*$scope.save_tastes = function () {
        var popup_id = $rootScope.show_popup({
            type: "simple-text",
            text: "Génération de votre profil gustatif en cours..."
        });
        $scope.loading = true;
        $scope.auth_user.food_tastes = $scope.user_tastes;
        $sdk.User.update_tastes($scope.auth_user, {
            success: function (updated_tastes) {
                $sdk.Log.save({
                    user_reference: $rootScope.auth_user._id,
                    value: "quizz_finished"
                }, {
                    success: function(){
                        $rootScope.apply_data($scope);
                    },
                    error: function(){
                        $rootScope.apply_data($scope);
                    }
                });
                $rootScope.close_popup(popup_id);
                $location.path('/user/filter');
                $rootScope.apply_data($scope);
            },
            error: function (error) {
                console.error(error);
                $scope.loading = false;
                
                $rootScope.close_popup(popup_id);
                $rootScope.apply_data($scope);
                
            }
        });
    };*/
