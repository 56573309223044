








































import Vue from "vue";
import { mapState } from "vuex";
import Card from "./Card.vue";
import Txt from "./Txt.vue";
import Button from "./Button.vue";

export default Vue.extend({
  components: {
    Card,
    Button,
    Txt,
  },
  computed: {
    ...mapState("authUser", ["_id"]),
    ...mapState("search", ["store"]),
    wineName() {
      //return `${this.wineColor} - ${this.data.wine_reference.winery.toLowerCase()}`;
      if (this.data.wine_reference.cuvee) {
        return this.data.wine_reference.cuvee.toLowerCase();
      } else if (this.data.wine_reference.winery) {
        return this.data.wine_reference.winery.toLowerCase();
      } else if (this.data.wine_reference.name) {
        return this.data.wine_reference.name.toLowerCase();
      } else {
        return "Vin inconnu";
      }
    },
    wineSubName() {
      return this.data.wine_reference.denomination.toLowerCase();
    },
    wineColor() {
      switch (this.data.wine_reference.color) {
        case "red": {
          return this.$t("typesWine.red");
        }
        case "white": {
          return this.$t("typesWine.white");
        }
        case "rose": {
          return this.$t("typesWine.rose");
        }
        default: {
          return this.data.wine_reference.color;
        }
      }
    },
    wineMainType() {
      switch (this.data.wine_reference.main_type) {
        case "still": {
          return this.wineColor;
        }
        case "sparkling": {
          if (this.data.wine_reference.color === "rose") {
            return "Rosé Eff.";
          } else {
            return this.wineLegalType;
          }
        }
        case "sweet": {
          return this.$t("typesWine.sweet");
        }
        default: {
          return this.data.wine_reference.main_type;
        }
      }
    },
    wineLegalType() {
      switch (this.data.wine_reference.legal_type) {
        case "dry": {
          return this.$t("typesLegalWine.dry");
        }
        case "brut": {
          return "Brut";
        }
        case "extra-dry": {
          return this.$t("typesLegalWine.extra-dry");
        }
        case "extra-brut": {
          return "Extra-Brut";
        }
        case "medium-dry": {
          return this.$t("typesLegalWine.medium-dry");
        }
        case "soft": {
          return this.$t("typesLegalWine.soft");
        }
        case "undosed": {
          return this.$t("typesLegalWine.undosed");
        }
        default: {
          return this.data.wine_reference.legal_type;
        }
      }
    },
  },
  methods: {
    toggleFavorite() {
      if (this.data.is_favorite) {
        this.$emit("favoriteClicked", this.data, false);
      } else {
        this.$emit("favoriteClicked", this.data, true);
      }
    },
  },
  props: ["data"],
});
