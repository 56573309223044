




















import Vue from "vue";
import { mapActions, mapState } from "vuex";
import Activity from "@/components/Activity.vue";
import Button from "@/components/Button.vue";
import Txt from "@/components/Txt.vue";
import ConceptAnimation from "@/components/ConceptAnimation.vue";
export default Vue.extend({
  name: "Home",
  components: {
    Activity,
    ConceptAnimation,
    Button,
    Txt,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapState("search", ["customer"]),
  },
  methods: {
    ...mapActions("authUser", ["setUser"]),
    next() {
      Vue.$sdk.User.save(
        {
          campaign: /*$routeParams.campaign ||*/ "fav-u-2021",
          notify_admin: false,
          licence: { has_actions_limit: false },
          customer_reference: this.customer._id,
        },
        {
          success: (data) => {
            Vue.$sdk.Auth.set_auth_user(data);
            this.setUser({ _id: data._id, token: data.token });
            Vue.$sdk.User.validate_achievement(data._id, "over_18", {
              success: () => {
                return;
              },
              error: (e) => {
                console.error(e);
              },
            });
            this.$router.push("/main");
            // this.setToken(data);
            /*
          $rootScope.auth_user = data;

          $sdk.Log.save({user_reference: data._id, value:"session_started"}, {
              success: function(){
                  $rootScope.apply_data($scope);
              },
              error: function(){
                  $rootScope.apply_data($scope);
              }
          });
          $rootScope.load_resources();
          $location.path("/home");
          $rootScope.apply_data($scope);*/
          },
          error: function (error) {
            console.error(error);
            //$rootScope.apply_data($scope);
          },
        }
      );
    },
  },
});
