






















































































































































import Vue from "vue";
import { mapActions, mapState } from "vuex";
import Activity from "@/components/Activity.vue";
import Button from "@/components/Button.vue";
import PageTitle from "@/components/PageTitle.vue";
import Card from "@/components/Card.vue";
import Txt from "@/components/Txt.vue";
import ToggleSwitch from "@/components/ToggleSwitch.vue";

export default Vue.extend({
  name: "Home",
  components: {
    Activity,
    Button,
    PageTitle,
    Card,
    Txt,
    ToggleSwitch,
  },
  data: function () {
    return {
      internalFilters: {
        type: {
          Red: true,
          Rose: true,
          White: true,
          //Fortified: true,
          Sparkling: true,
          Sweet: true,
        },
        price: {
          cheap: true,
          budget: true,
          medium: true,
          expensive: true,
        },
        bio: false,
      },
    };
  },
  mounted() {
    if (this.filters) {
      this.internalFilters = this.filters;
    }
  },
  computed: {
    ...mapState("search", ["filters"]),
    ...mapState("authUser", ["_id"]),
    disabled() {
      return (
        !Object.values(this.internalFilters.price).some((p) => p) ||
        !Object.values(this.internalFilters.type).some((t) => t)
      );
    },
  },
  methods: {
    ...mapActions("search", ["setFilters", "setShouldMatch"]),
    toggleFilter(filter, key) {
      this.internalFilters[filter][key] = !this.internalFilters[filter][key];
    },
    next() {
      this.setFilters(this.internalFilters);
      this.setShouldMatch(true);
      Vue.$sdk.User.update_session(
        this._id,
        { "current_session.quiz_time": new Date().getTime(), filters: this.internalFilters },
        {
          success: () => {
            this.$router.push("/main");
          },
          error: (error) => {
            //[TO-DO]
            alert(error);
          },
        }
      );
    },
  },
});
