var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.wine)?_c('Activity',{staticClass:"page-quizz"},[_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"buttons-container"},[_c('Button',{attrs:{"size":"xl","type":"secondary"},on:{"click":function($event){return _vm.$router.push('/main')}}},[_vm._v(_vm._s(_vm.$t("global.back")))])],1)]),_c('div',{staticClass:"wine-container"},[_c('Row',[_c('Card',[_c('div',{staticClass:"wine-image",style:('background-image: url(' + _vm.wine.wine_reference.image + ')')}),_c('Button',{attrs:{"id":"zoom-button","size":"lg","type":"primary"},on:{"click":function($event){_vm.showPhotoModal = true}}},[_c('img',{attrs:{"src":require('../assets/img/icons/search.svg')}})])],1),_c('Card',{staticClass:"wine-main-infos"},[_c('Txt',{attrs:{"size":"lg"}},[_vm._v(_vm._s(_vm.wineName))]),_c('Txt',{attrs:{"type":"light"}},[_vm._v(_vm._s(_vm.wineSubName))]),_c('Txt',{attrs:{"size":"xl","type":"light"}},[_vm._v(_vm._s(_vm.wine.wine_reference.year))]),_c('Separator'),_c('Row',[_c('div',[_c('Txt',[_vm._v(_vm._s(_vm.wineColor)+" "+_vm._s(_vm.wineMainType))]),_c('Txt',{attrs:{"size":"lg","type":"light"}},[_vm._v(_vm._s(_vm.wine.price.toFixed(2))+_vm._s(_vm.$t("global.currency")))])],1),_c('img',{attrs:{"height":"50","id":"barcode"}})])],1)],1),_c('div',{staticClass:"warning"},[_c('Separator',{attrs:{"type":"light"}}),_c('Txt',{attrs:{"align":"center","type":"white"}},[_vm._v(_vm._s(_vm.$t("wineFile.alcoholInstruction")))]),_c('Separator',{attrs:{"type":"light"}})],1),(_vm.wine.shopping_link)?_c('Row',[_c('Card',{staticStyle:{"flex":"1"}},[_c('Txt',[_vm._v(_vm._s(_vm.$t("wineFile.text")))]),_c('div',{staticStyle:{"text-align":"right","align-self":"flex-end","margin-top":"10px"}},[_c('Button',{attrs:{"type":"important"},on:{"click":function($event){return _vm.open(
                _vm.wine.shopping_link +
                  '?store=' +
                  _vm.store.code +
                  '&utm_campaign=2021-winesee&utm_medium=display&utm_source=winesee&utm_content=opwinesee&utm_term=emplacement'
              )}}},[_vm._v(_vm._s(_vm.$t("global.buy")))])],1)],1)],1):_vm._e(),_c('Row',[_c('Card',{staticClass:"wine-score",staticStyle:{"flex":"1"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/fire.svg")}}),_c('Txt',[_c('strong',[_vm._v(_vm._s(_vm.$t("wineFile.profilTaste"))+" "+_vm._s(_vm.$route.query.score)+"%")]),_vm._v(" "+_vm._s(_vm.$t("wineFile.profilTaste2")))])],1)],1),(_vm.wine.wine_reference.ecological_mentions)?_c('Row',[_c('Card',{staticClass:"wine-score",staticStyle:{"flex":"1"}},[_c('img',{attrs:{"src":require("@/assets/img/icons/earth.svg")}}),_c('Txt',[_vm._v(_vm._s(_vm.$t("wineFile.wineBio"))+" ")])],1)],1):_vm._e(),_c('Row',[_c('Card',{staticClass:"wine-description"},[_c('vue-word-cloud',{staticStyle:{"height":"300px","width":"100%"},attrs:{"words":_vm.words,"color":function (ref) {
                            var weight = ref[1];

                            return 'rgba(52, 152, 219, ' + weight / 10 + ')';
},"font-family":"Roboto"}})],1)],1)],1),_c('PhotoModal',{attrs:{"show":_vm.showPhotoModal,"image":_vm.wine.wine_reference.image},on:{"close":function($event){_vm.showPhotoModal = false}}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }