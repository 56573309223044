







import Card from "./Card.vue";
import Vue from "vue";
export default Vue.extend({
  components: {
    Card,
  },
  props: {
    name: String,
    image: String,
    type: String,
    selected: Boolean,
    checked: Boolean,
  },
});
