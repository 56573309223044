var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Card',{class:['wine-thumbnail', 'wine-' + _vm.data.wine_reference.color]},[_c('button',{class:['favorite-button', _vm.data.is_favorite ? 'active' : null],on:{"click":_vm.toggleFavorite}},[_c('span',[_vm._v("Mettre en favoris")])]),_c('div',{staticClass:"wine-image",style:('background-image: url(' + _vm.data.wine_reference.image + ')')}),_c('div',{staticClass:"wine-info"},[_c('div',{staticClass:"wine-name"},[_c('Txt',{staticClass:"wine-title",attrs:{"size":"m"}},[_vm._v(_vm._s(_vm.wineName))]),_c('Txt',{staticClass:"wine-subtitle",attrs:{"type":"light"}},[_vm._v(_vm._s(_vm.wineSubName))])],1),_c('div',{staticClass:"wine-badges"},[(_vm.data.wine_reference.ecological_mentions)?_c('span',{staticClass:"badge badge-score"},[_c('img',{attrs:{"src":require("@/assets/img/icons/earth.svg")}})]):_vm._e(),_c('span',{staticClass:"badge badge-score"},[_c('img',{attrs:{"src":require("@/assets/img/icons/fire.svg")}}),_vm._v(_vm._s(_vm.data.distance.toFixed(2))+"%")]),_c('span',{staticClass:"badge badge-color"},[_vm._v(_vm._s(_vm.wineMainType))])])]),_c('div',{staticClass:"wine-cta"},[_c('Txt',{staticClass:"wine-price",attrs:{"size":"xl","type":"light"}},[_vm._v(_vm._s(_vm.data.price.toFixed(2))+_vm._s(_vm.$t("global.currency")))]),_c('Button',{attrs:{"type":"important"},on:{"click":function($event){_vm.$router.push(
          '/wine?storeId=' +
            _vm.store._id +
            '&wineId=' +
            (_vm.data.wine_info_reference._id || _vm.data.wine_info_reference) +
            '&score=' +
            _vm.data.distance.toFixed(2)
        )}}},[_vm._v(_vm._s(_vm.$t("main.seeYourWine"))),_c('span',{staticClass:"hide-mobile"},[_vm._v(_vm._s(_vm.$t("main.seeYourWineMobileHidden")))])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }