/* eslint-disable */
import { $sdk } from "winesee-sdk/src/index.js";
import Vue, { VueConstructor, PluginObject } from "vue";

const SdkPlugin: PluginObject<unknown> = {
  install(Vue: VueConstructor<Vue, Vue>) {
    Vue.$sdk = new $sdk();
  },
};
export default SdkPlugin;
